<template>
  <div>
    <!-- select 2 demo -->

    <div>
      <!-- Table Top -->
      <div>
        <b-sidebar
          id="sidebar-right"
          ref="mySidebar"
          bg-variant="white"
          v-model="visibility"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">
              {{ sidebarTitle }}
            </h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hideSideBar()"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="mt-1">
              <b-col md="12" v-if="adding">
                <b-form-group
                  label="Subject *"
                  invalid-feedback="Subject is required."
                  ref="subject"
                >
                  <b-form-textarea
                    ref="subject"
                    placeholder="Enter subjects seperated by comma"
                    v-model="myObj.subject"
                    rows="4"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>

              <b-col md="12" v-else>
                <b-form-group
                  label="Subject *"
                  invalid-feedback="Subject is required."
                  ref="subject"
                >
                  <b-form-input
                    id="mc-first-name"
                    placeholder="Enter subject name"
                    v-validate="'required'"
                    name="classField"
                    ref="subject"
                    v-model.trim="myObj.subject"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12" class="mb-1 d-flex">
                <b-button
                  :variant="myObj.isOptional ? 'outline-primary' : 'primary'"
                  class="mr-1"
                  style="width: 50%"
                  @click="myObj.isOptional = false"
                >
                  <span>Mandatory</span>
                </b-button>
                <b-button
                  :variant="myObj.isOptional ? 'primary' : 'outline-primary'"
                  @click="myObj.isOptional = true"
                  style="width: 50%"
                >
                  <span>Optional</span>
                </b-button>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Classes"
                  invalid-feedback="Class is required."
                  ref="class"
                >
                  <v-select
                    multiple
                    :closeOnSelect="false"
                    v-model="classIDs"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="classesOptions"
                    :clearable="false"
                    label="name"
                    :reduce="(val) => val.id"
                    placeholder="Select classes"
                    @input="setClass()"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12" class="mt-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  @click="AddNew()"
                  :disabled="request"
                  block
                >
                  <!-- @click="Add()"  -->
                  <b-spinner v-if="request" small type="grow" />
                  <span v-else>{{ sidebarButton }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-card>
          <b-row class="mt-1">
            <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
              <b-button
                @click="AddOpen()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">Add Subject</span>
              </b-button>
            </b-col>

            <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
              <b-button
                @click="openCat()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">Groups</span>
              </b-button>
            </b-col>

            <b-col
              class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
              xl="8"
              lg="6"
              md="4"
              sm="12"
              cols="12"
            >
              <b-form-group class="">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>

                  <b-form-input v-model="searchQuery" placeholder="Search...">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-table
            class="mt-1"
            ref="selectableTable"
            :items="filters"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :busy="dataLoading"
            show-empty
            responsive
            hover
            :selectable="rights.edit"
            select-mode="single"
            @row-selected="Edit($event[0])"
          >
            <template #empty="scope">
              <h3 class="mt-1 mb-1" style="text-align: center">
                No records found
              </h3>
            </template>
            <template #table-busy>
              <div
                class="d-flex justify-content-center mb-3"
                style="margin-top: 50px"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  type="grow"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>
            <template #cell(name)="data">
              <b-badge style="margin-inline: 2px" variant="light-primary">
                {{ data.item.name }}
              </b-badge>
            </template>

            <template #head(actions)="data">
              <div class="text-center">
                <span>{{ data.label }}</span>
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="text-center">
                <b-button
                  v-if="rights.edit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-b-tooltip.hover.left
                  title="Edit"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Edit(data.item)"
                  :disabled="editLoading && currentID == data.item.id"
                >
                  <b-spinner
                    v-if="editLoading && currentID == data.item.id"
                    small
                  />
                  <feather-icon v-else icon="EditIcon" class="" />
                </b-button>

                <b-button
                  v-if="rights.delete"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.right
                  title="Delete"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Delete(data.item.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </template>
          </b-table>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BFormTextarea,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
      this.LoadClasses();
    }
    // this.LoadData();
    // this.LoadClasses();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.items.filter((pro) => {
        return pro.subject.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      // rights: {
      //   add: true,
      //   edit: true,
      //   delete: true,
      // },
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,
      color: [
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
      ],

      sidebarTitle: "Add Subject",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "Subject", key: "subject" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      rangeDate: null,
      adding: false,
      myObj: {
        id: 0,
        subject: "",
        campusID: this.$store.state.userData.cId,
      },
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
      classesOptions: [],
      classIDs: [],
      editLoading: false,
      currentID: 0,
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    setClass() {
      if (this.classIDs.at(-1) == 0) {
        this.classIDs = [0];
      } else this.classIDs = this.classIDs.filter((el) => el != 0);
    },

    openCat() {
      this.$router.push("/subjects");
    },

    async Edit(item) {
      this.editLoading = true;
      this.currentID = item.id;
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects/LoadWithClass?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&subjectID=" +
          item.id,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      // console.log(res);
      this.editLoading = false;
      this.currentID = 0;
      if (res.subjectID == 0) {
        this.$bvToast.toast("Something went wrong!", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.myObj = { ...item };
        this.myObj.subject = res.subjects;
        this.myObj.isOptional = res.isOptional;
        this.classIDs = res.classIDs;

        // console.log("editObj:", this.myObj, this.classIDs);
        this.adding = false;
        this.visibility = true;
        this.sidebarTitle = "Edit Subject";
        this.sidebarButton = "Update";
        var elem = this.$refs["subject"];
        elem.state = undefined;
      }
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        subject: "",
        campusID: this.$store.state.userData.cId,
        isOptional: false,
      };
      this.classIDs = [];
      this.adding = true;
      this.visibility = true;
      this.sidebarTitle = "Add Subject";
      this.sidebarButton = "Save";
      var elem = this.$refs["subject"];
      elem.state = undefined;
    },

    CheckName() {
      var elem = this.$refs["subject"];
      if (this.myObj.subject.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    CheckClass() {
      var elem = this.$refs["class"];
      if (this.classIDs.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      if (this.items.length != 0) {
        this.totalRows = this.items.length;
      }
      this.dataLoading = false;
      // console.log("subject", this.items);
    },
    async LoadClasses() {
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      this.classesOptions = [{ id: 0, name: "All Classes" }];
      res.forEach((el) => this.classesOptions.push(el.cls));
    },

    async Add() {
      this.CheckName();
      if (this.CheckName() == false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.myObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Subjects/AddMultiple?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId +
              "&subjects=" +
              this.myObj.subject,
            body: this.myObj,
            message: "Subject added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadData();
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "Subjects/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Subject updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadData();
        }
      }
    },

    async AddNew() {
      this.CheckName();
      if (this.CheckName() == false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        let clsIDs = [];
        if (this.classIDs[0] == 0) {
          clsIDs = this.classesOptions.reduce((acc, el) => {
            if (el.id != 0) acc.push(el.id);
            return acc;
          }, []);
        } else clsIDs = this.classIDs;
        // console.log(clsIDs);

        if (this.myObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Subjects/AddMultipleWithClass?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: {
              subjectID: 0,
              subjects: this.myObj.subject,
              classIDs: clsIDs,
              isOptional: this.myObj.isOptional,
            },
            message: "Subject added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadData();
        } else {
          //Edit
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Subjects/EditWithClass?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: {
              subjectID: this.myObj.id,
              subjects: this.myObj.subject,
              classIDs: clsIDs,
              isOptional: this.myObj.isOptional,
            },
            message: "Subject updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadData();
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Subjects/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Subject removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
